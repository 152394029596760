exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-index-jsx": () => import("./../../../src/pages/home/index.jsx" /* webpackChunkName: "component---src-pages-home-index-jsx" */),
  "component---src-pages-home-ui-advantage-index-jsx": () => import("./../../../src/pages/home/ui/advantage/index.jsx" /* webpackChunkName: "component---src-pages-home-ui-advantage-index-jsx" */),
  "component---src-pages-home-ui-check-index-jsx": () => import("./../../../src/pages/home/ui/check/index.jsx" /* webpackChunkName: "component---src-pages-home-ui-check-index-jsx" */),
  "component---src-pages-home-ui-faq-index-jsx": () => import("./../../../src/pages/home/ui/faq/index.jsx" /* webpackChunkName: "component---src-pages-home-ui-faq-index-jsx" */),
  "component---src-pages-home-ui-first-index-jsx": () => import("./../../../src/pages/home/ui/first/index.jsx" /* webpackChunkName: "component---src-pages-home-ui-first-index-jsx" */),
  "component---src-pages-home-ui-interest-index-jsx": () => import("./../../../src/pages/home/ui/interest/index.jsx" /* webpackChunkName: "component---src-pages-home-ui-interest-index-jsx" */),
  "component---src-pages-home-ui-number-index-jsx": () => import("./../../../src/pages/home/ui/number/index.jsx" /* webpackChunkName: "component---src-pages-home-ui-number-index-jsx" */),
  "component---src-pages-home-ui-online-index-jsx": () => import("./../../../src/pages/home/ui/online/index.jsx" /* webpackChunkName: "component---src-pages-home-ui-online-index-jsx" */),
  "component---src-pages-home-ui-partner-index-jsx": () => import("./../../../src/pages/home/ui/partner/index.jsx" /* webpackChunkName: "component---src-pages-home-ui-partner-index-jsx" */),
  "component---src-pages-home-ui-price-index-jsx": () => import("./../../../src/pages/home/ui/price/index.jsx" /* webpackChunkName: "component---src-pages-home-ui-price-index-jsx" */),
  "component---src-pages-home-ui-review-index-jsx": () => import("./../../../src/pages/home/ui/review/index.jsx" /* webpackChunkName: "component---src-pages-home-ui-review-index-jsx" */),
  "component---src-pages-home-ui-work-index-jsx": () => import("./../../../src/pages/home/ui/work/index.jsx" /* webpackChunkName: "component---src-pages-home-ui-work-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-payment-index-jsx": () => import("./../../../src/pages/payment/index.jsx" /* webpackChunkName: "component---src-pages-payment-index-jsx" */),
  "component---src-pages-privacy-index-jsx": () => import("./../../../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */)
}

